.filterSelects {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 5px;
}

.select-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 7px;
  margin-top: 3px;
}
