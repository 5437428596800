.not-local-wrapper {
  height: 400;
  width: 100%;
  padding-bottom: 1;
}

.local-wrapper {
  height: 300;
  width: 100%;
  padding: 1;
}

.doc-num {
  font-style: bold;
  color: blue;
}

.highlight-blue {
  font-weight: bold;
  color: rgb(0, 40, 198);
}

.highlight-green {
  font-weight: bold;
  color: rgb(2, 100, 26);
}

.highlight-red {
  font-weight: bold;
  color: rgb(179, 17, 17);
}

.divider-title {
  font-family: "Merriweather", sans-serif;
  font-weight: bold;
  font-size: large;
  text-align: center;
}
