.create-header {
  display: flex;
  width: 100%;
}

.form-bg {
  background-color: white;
  border: 2px solid rgb(181, 181, 181);
  border-radius: 5px;
  margin: 0px;
  padding: 0px;
}

.form-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px;
  padding: 2.5rem;
  padding-top: 1.5rem;
  border-radius: 5px;
}

.error-msg {
  font-size: 14px;
  color: red;
  font-style: italic;
  padding-bottom: 7px;
  margin: 0px;
  text-align: left;
}

.error-container {
  display: flex;
  justify-content: flex-start;
  margin: 0px;
  margin-top: 15px;
  margin-bottom: 5px;
}

.back-btn-wrapper {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.header-padding {
  padding-top: 1rem;
  padding-left: 1rem;
}

.divider-disabled {
  color: grey;
  font-family: Merriweather, Arial, Helvetica, sans-serif;
}

.divider {
  font-family: Merriweather, Arial, Helvetica, sans-serif;
}

.printer-page {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
}

.printer-info-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.info-blurb {
  color: #b65c07;
  font-style: italic;
}
