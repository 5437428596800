.actions-buttons {
  display: inline-flex;
  justify-content: center;
  width: 100%;
}

.delete-btn {
  border: none;
}

.delete-btn:hover {
  cursor: pointer;
}

.inventory-btns-container {
  display: flex;
  justify-content: space-between;
  margin-left: 2px;
}

.add-import-wrapper {
  display: flex;
  align-items: center;
  width: 60%;
}

.basic-btn-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.basic-btn-wrapper {
  display: flex;
  align-items: center;
}

.editable:hover {
  color: blue;
  /* font-weight: 500; */
}

.signature-needed:enabled {
  display: flex;
  align-items: center;
  text-transform: none !important;
  font-family: Roboto, sans-serif !important;
  color: #1e3141 !important;
  cursor: pointer;
}

.signature-needed:disabled {
  display: flex;
  align-items: center;
  text-transform: none !important;
  font-family: Roboto, sans-serif !important;
}

.hazmat-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.NA-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.signature-needed-NA:disabled {
  display: flex;
  text-align: left;
  text-transform: none !important;
  font-family: Roboto, sans-serif !important;
}

.confirm-icon:enabled {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1e3141 !important;
  width: 100%;
}

.confirm-icon:disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.error-icon-wrapper:enabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  pointer-events: none;
  color: #c2630afa !important;
  cursor: default !important;
}

.cancel-icon:enabled {
  color: #b913138c !important;
}

.cancel-icon:enabled:hover {
  color: #f30e0e !important;
  transform: scale(1.2);
}
