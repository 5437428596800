.issued-year-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-left: 2px;
  margin-bottom: 10px;
  font-family: Merriweather, Arial, Helvetica, sans-serif;
  font-size: medium;
  font-weight: 600;
  width: 100%;
}
